/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'drawer_show': {
    width: 8,
    height: 14,
    viewBox: '0 0 8 14',
    data: '<path pid="0" d="M1.077 1.378l5.339 5.339a.45.45 0 010 .636l-5.339 5.339" _stroke="#FFF" stroke-width="2" _fill="none" fill-rule="evenodd" stroke-linecap="round"/>'
  }
})
